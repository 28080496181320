/* Mobile first */
.v2Logo {
  width: calc(var(--v2-logo-width) / 4);
  height: calc(var(--v2-logo-height) / 4);
}

.linkedinLogo {
  width: calc(var(--linkedin-logo-height) * 2 / 4);
  height: calc(var(--linkedin-logo-height) * 2 / 4);
}

.youtubeLogo {
  height: calc(var(--youtube-logo-height) * 2 / 4);
}

.githubLogo {
  width: calc(var(--github-logo-height) * 2 / 4);
  height: calc(var(--github-logo-height) * 2 / 4);
}

.mediumLogo {
  width: calc(var(--medium-logo-height) * 2 / 4);
  height: calc(var(--medium-logo-height) * 2 / 4);
}

/* Tablet - 48em = 768px */
@media (min-width: 48em) {
  .v2Logo {
    width: calc(var(--v2-logo-width) / 3);
    height: calc(var(--v2-logo-height) / 3);
  }

  .linkedinLogo {
    width: calc(var(--linkedin-logo-height) * 2 / 3);
    height: calc(var(--linkedin-logo-height) * 2 / 3);
  }

  .youtubeLogo {
    height: calc(var(--youtube-logo-height) * 2 / 3);
  }

  .githubLogo {
    width: calc(var(--github-logo-height) * 2 / 3);
    height: calc(var(--github-logo-height) * 2 / 3);
  }

  .mediumLogo {
    width: calc(var(--medium-logo-height) * 2 / 3);
    height: calc(var(--medium-logo-height) * 2 / 3);
  }
}

/* Desktop - 64em = 1024px */
@media (min-width: 64em) {
  .v2Logo {
    width: calc(var(--v2-logo-width) / 2);
    height: calc(var(--v2-logo-height) / 2);
  }

  .linkedinLogo {
    width: var(--linkedin-logo-height);
    height: var(--linkedin-logo-height);
  }

  .youtubeLogo {
    height: var(--youtube-logo-height);
  }

  .githubLogo {
    width: var(--github-logo-height);
    height: var(--github-logo-height);
  }

  .mediumLogo {
    width: var(--medium-logo-height);
    height: var(--medium-logo-height);
  }
}
