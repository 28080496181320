/* Mobile first */

/* menu icon */

.navicon {
  display: block;
  height: 2px;
  position: relative;
  transition: background 200ms;
  width: 18px;
}

.navicon:before,
.navicon:after {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  /* transition disabled due to "rotate" animation bug in iOS Chrome... Only the most common browser on the common phone... */
  /* transition works perfectly fine on all other iOS browsers i.e. Brave, Safari, Firefox, DuckDuckGo, Opera and even Edge */
  /* Issue details can be found in comments of https://stackoverflow.com/questions/49384466/css-transition-not-working-in-mobile-chrome */
  /* transition: all 200ms ease-out; */
  width: 100%;
}

.navicon:before {
  top: 5px;
}

.navicon:after {
  top: -5px;
}

@media (max-width: 1023px) {
  .top-container {
    justify-content: space-between;
    width: 100%;
    z-index: 10;
  }

  .menu {
    flex-direction: column;
    transition: left 400ms;
    background-color: white;
    width: 100vw;
    position: fixed;
    left: calc(-100vw - 10%);
    padding-top: 48px;
  }

  .top-container:has(> .menuBtn:checked) ~ .menu {
    left: 0;
  }

  .menuBtn ~ .menuIcon .navicon {
    transition: all 400ms;
  }

  .menuBtn ~ .menuIcon .navicon:before {
    transition: all 400ms;
  }

  .menuBtn ~ .menuIcon .navicon:after {
    transition: all 400ms;
  }

  .menuBtn:checked ~ .menuIcon .navicon {
    background: transparent;
  }

  .menuBtn:checked ~ .menuIcon .navicon:before {
    transform: rotate(-45deg);
  }

  .menuBtn:checked ~ .menuIcon .navicon:after {
    transform: rotate(45deg);
  }

  .menuBtn:checked ~ .menuIcon:not(.steps) .navicon:before,
  .menuBtn:checked ~ .menuIcon:not(.steps) .navicon:after {
    top: 0;
  }

  .menu li button {
    margin-top: 3rem;
  }
}
